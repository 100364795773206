import React from 'react';
import styles from './FooterLegal.module.scss';

type FooterLegalProps = {
  children: React.ReactNode | React.ReactNode[];
};

export const FooterLegal = ({ children }: FooterLegalProps) => (
  <div className={styles.root}>
    <span>{children}</span>
  </div>
);
