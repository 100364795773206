import React from 'react';
import { FooterAcknowledgementOfCountry } from './navigation/components/FooterAcknowledgementOfCountry';
import { FooterChannels } from './navigation/components/FooterChannels';
import { FooterContact } from './navigation/components/FooterContact';
import { FooterContainer } from './navigation/components/FooterContainer';
import { FooterContent } from './navigation/components/FooterContent';
import { FooterDomain } from './navigation/components/FooterDomain';
import { FooterLastColumn } from './navigation/components/FooterLastColumn';
import { FooterLegal } from './navigation/components/FooterLegal';
import { FooterLinks } from './navigation/components/FooterLinks';
import { FooterLogos } from './navigation/components/FooterLogos';
import { FooterSecurity } from './navigation/components/FooterSecurity';
import { FooterShade } from './navigation/components/FooterShade';
import { FooterSocials } from './navigation/components/FooterSocials';
import { Text } from './Text';
import { Link } from './Link';
import { footerLogos, footerSocials } from '../content';
import { Container } from './Container';
import { TextMarginBottom } from '../constants/TextMarginBottom';
import { TextSizes } from '../constants/TextSizes';

export const Footer = () => (
  <>
    <FooterAcknowledgementOfCountry>
      <Container>
        <Text size={TextSizes.SMALL} marginBottom={TextMarginBottom.NONE}>
          <strong>
            Athena acknowledges the traditional owners of the land on which we gather the Gadigal people of the Eora
            nation. We acknowledge that sovereignty was never ceded and respect their continued and continuing
            connection to this place.
          </strong>
          <br />
        </Text>
      </Container>
    </FooterAcknowledgementOfCountry>
    <FooterContainer>
      <FooterContent>
        <FooterDomain>
          <FooterLinks title="Learn">
            <Link href="/about">About Athena</Link>
            <Link href="/hardship-assist">Athena Assist</Link>
            <Link href="/athena-select">Athena Select</Link>
            <Link href="/eligibility">Check your eligibility</Link>
            <Link href="/faq/about-athena">Frequently asked questions</Link>
            <Link href="/home-loan-hacks">Pay your loan down faster</Link>
          </FooterLinks>
          <FooterLinks title="Legal">
            <a href="/access-terms" rel="noopener noreferrer" target="_blank">
              Access Terms
            </a>
            <a href="/target-market" rel="noopener noreferrer" target="_blank">
              Athena&apos;s Target Market
            </a>
            <a href="https://athena.bamboohr.com/jobs/" rel="noopener noreferrer" target="_blank">
              Careers
            </a>
            <a href="/credit-guide" rel="noopener noreferrer" target="_blank">
              Credit Guide
            </a>
            <a href="/privacy-policy" rel="noopener noreferrer" target="_blank">
              Privacy Policy
            </a>
          </FooterLinks>
          <FooterLinks title="Careers">
            <Link href="https://athena.bamboohr.com/jobs">Join us</Link>
          </FooterLinks>
          <FooterLastColumn forMobile={false}>
            <FooterLogos logos={footerLogos} />
            <FooterSecurity>
              We care about your information and privacy.
              {' '}
              <Link href="/security">
                <b>Read more about our security measures and recommendations.</b>
              </Link>
            </FooterSecurity>
            <FooterLegal>
              ALL APPLICATIONS ARE SUBJECT TO ELIGIBILITY AND ASSESSMENT
              <br />
              {' '}
              ATHENA MORTGAGE PTY LTD | ABN 24 619 536 506 | AUSTRALIAN CREDIT LICENCE 502611
            </FooterLegal>
          </FooterLastColumn>
        </FooterDomain>
        <FooterChannels>
          <FooterContact title="Get in touch" />
          <FooterSocials socialPlatforms={footerSocials} />
          <FooterLastColumn forMobile>
            <FooterLogos logos={footerLogos} />
            <FooterSecurity>
              We care about your information and privacy.
              {' '}
              <Link href="/security">
                <b>Read more about our security measures and recommendations.</b>
              </Link>
            </FooterSecurity>
            <FooterLegal>
              ALL APPLICATIONS ARE SUBJECT TO ELIGIBILITY AND ASSESSMENT
              <br />
              ATHENA MORTGAGE PTY LTD | ABN 24 619 536 506 | AUSTRALIAN CREDIT LICENCE 502611
            </FooterLegal>
          </FooterLastColumn>
        </FooterChannels>
      </FooterContent>
      <FooterShade />
    </FooterContainer>
  </>
);
