import React from 'react';
import styles from './FooterLinks.module.scss';

type FooterLinksProps = {
  children: React.ReactNode | React.ReactNode[];
  title: string;
};

export const FooterLinks = ({ children, title }: FooterLinksProps) => {
  const arrayChildren = React.Children.toArray(children);

  return (
    <div className={styles.root}>
      <h3 className={styles.title}>{title}</h3>
      <ul className={styles.list}>
        {arrayChildren.map((child, childIndex) => (
          <li key={childIndex} className={styles['list-item']}>
            {child}
          </li>
        ))}
      </ul>
    </div>
  );
};
