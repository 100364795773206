import React from 'react';
import styles from './FooterSocials.module.scss';

type FooterSocialsProps = {
  socialPlatforms: Array<{ name: string; url: string; logo: string }>;
};

export const FooterSocials = ({ socialPlatforms }: FooterSocialsProps) => (
  <div className={styles.root}>
    {socialPlatforms.map(({ name, logo, url }) => (
      <a
        key={name}
        href={url}
        rel="external noreferrer noopener"
        target="_blank"
        aria-label={`Visit Athena on ${name} (opens in a new window)`}
        title={`Athena on ${name}`}
      >
        <img className={styles.image} src={logo} alt={`Athena on ${name}`} width="24" height="24" loading="lazy" />
      </a>
    ))}
  </div>
);
