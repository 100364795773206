import React from 'react';
import styles from './FooterContainer.module.scss';

type FooterContainerProps = {
  children: React.ReactNode | React.ReactNode[];
};

export const FooterContainer = ({ children }: FooterContainerProps) => (
  <footer className={styles.root} role="contentinfo">
    <div className={styles.container}>{children}</div>
  </footer>
);
