import React from 'react';
import styles from './FooterLastColumn.module.scss';

interface IFooterLastColumn {
  forMobile: boolean;
  children: React.ReactNode | React.ReactNode[];
}

export const FooterLastColumn = ({ forMobile = false, children }: IFooterLastColumn) => (
  <div className={`${styles.root} ${forMobile ? styles.mobile : styles.desktop}`}>{children}</div>
);
