import React from 'react';
import { common } from './common';
import styles from './Content.module.scss';

export const openingHours = (
  <>
    <span className={styles['open-hours']}>{common.phone.days}</span>
    {' '}
&nbsp;
    {common.phone.hours}
  </>
);

export const contactDetails = [
  {
    label: 'SMS',
    href: common.sms.href,
    string: common.sms.string,
  },
  {
    label: 'LOCAL CALL',
    href: common.phone.href,
    string: common.phone.string,
  },
  {
    label: 'EMAIL',
    href: common.email.href,
    string: common.email.string,
  },
];

export const contactUsLink = {
  label: 'ONLINE',
  to: common.contact,
  string: 'Message us',
};

export const footerLogos = [
  {
    name: 'Certified B Corporation',
    url: 'https://www.bcorporation.net/en-us/find-a-b-corp/company/athena-financial-p-t-y-l-t-d',
    image: '/next-assets/images/logos/ic-BCorp_2x.webp',
  },
];

export const footerSocials = [
  { name: 'facebook', url: 'https://www.facebook.com/AthenaHomeLoans', logo: '/next-assets/images/logos/facebook.svg' },
  {
    name: 'instagram',
    url: 'https://www.instagram.com/athenahomeloans/',
    logo: '/next-assets/images/logos/instagram.svg',
  },
  {
    name: 'linkedin',
    url: 'https://www.linkedin.com/company/athenahomeloans',
    logo: '/next-assets/images/logos/linkedin.svg',
  },
  { name: 'twitter', url: 'https://twitter.com/athenahomeloans', logo: '/next-assets/images/logos/twitter.svg' },
];
