import React from 'react';
import styles from './FooterSecurity.module.scss';

type FooterSecurityProps = {
  children: React.ReactNode | React.ReactNode[];
};

export const FooterSecurity = ({ children }: FooterSecurityProps) => (
  <div className={styles.root}>
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 9.5C15.1046 9.5 16 10.3954 16 11.5V15.5C16 16.6046 15.1046 17.5 14 17.5H6C4.89543 17.5 4 16.6046 4 15.5V11.5C4 10.3954 4.89543 9.5 6 9.5V7.5C6 5.29086 7.79086 3.5 10 3.5C12.2091 3.5 14 5.29086 14 7.5V9.5ZM10 4.83333C11.4728 4.83333 12.6667 6.02724 12.6667 7.5V9.5H7.33333V7.5C7.33333 6.02724 8.52724 4.83333 10 4.83333ZM14 10.8333H6C5.63181 10.8333 5.33333 11.1318 5.33333 11.5V15.5C5.33333 15.8682 5.63181 16.1667 6 16.1667H14C14.3682 16.1667 14.6667 15.8682 14.6667 15.5V11.5C14.6667 11.1318 14.3682 10.8333 14 10.8333Z"
        fill="white"
      />
    </svg>

    <p className={styles.paragraph}>{children}</p>
  </div>
);
