import React from 'react';
import styles from './FooterLogos.module.scss';

type FooterLogosProps = {
  logos: Array<{
    name: string;
    url: string;
    image: string;
  }>;
};

export const FooterLogos = ({ logos }: FooterLogosProps) => (
  <div className={styles.root}>
    {logos.map(({ name, image, url }) => (
      <a key={name} href={url} rel="external noreferrer noopener" target="_blank" aria-label={name} title={name}>
        <img src={image} alt={name} width="80" height="80" loading="lazy" />
      </a>
    ))}
  </div>
);
